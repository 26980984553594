import React from 'react'
import { FooterWrapper } from "./styled.components"

export const Footer = () => {
  return (
    <>
      <FooterWrapper>
      © 2023 | Innv8e Trust | This website was crafted by The Peoples Communication Company (PTY) LTD.
      </FooterWrapper>

    </>
  )
}
