import {InfoCard} from './styled.components';
import {MainImage} from './styled.components'
import image1 from '../assets/innv8e-trust.jpeg';


export const Collage = () => {
    return (<>
        <MainImage>

            <img src={image1} alt="" />

        </MainImage>
        <InfoCard>
            <div className='info' >

                <h5 className='h5' >COMING <strong>SEPTEMBER 2023.</strong></h5>
                <h3 className='h3' >An Exciting Project By WWW.KEMETDESIGNSTUDIO.ORG.</h3>
            </div>


        </InfoCard>
    </>
    );
}