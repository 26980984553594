import React from 'react';
import { HeaderWrapper, LogoText } from './styled.components';

const Header = () => {
    return (
        <HeaderWrapper>
            <LogoText>
                <strong> INNV8E TRUST </strong>
            </LogoText>
        </HeaderWrapper>
    );
};
export default Header;
